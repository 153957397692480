const baseUrl = "";
const ourEstatesSlug = `${baseUrl}/our-estates`;
const getStartedSlug = `${baseUrl}/get-started`;
const aboutUsSlug = `${baseUrl}/about-us`;
const resourcesSlug = `${baseUrl}/resources`;

export const Routes = {
  index: () => `${baseUrl}/`,

  // our-estates
  ourEstates: () => `${ourEstatesSlug}`,
  viewEstate: (slug: string) => `${ourEstatesSlug}/${slug}`,
  viewProperty: (slug: string, estate: string) =>
    `${ourEstatesSlug}/${estate}/${slug}`,

  // get-started
  getStarted: () => `${getStartedSlug}`,
  scheduleInspection: () => `${getStartedSlug}/schedule-inspection`,
  buy: () => `${getStartedSlug}/buy`,
  rent: () => `${getStartedSlug}/rent`,
  shortlets: () => `${getStartedSlug}/shortlets`,

  // about-us
  aboutUs: () => `${aboutUsSlug}`,
  company: () => `${aboutUsSlug}/company`,
  founder: () => `${aboutUsSlug}/sade-balogun`,
  careers: () => `${aboutUsSlug}/careers`,
  csr: () => `${aboutUsSlug}/csr`,

  // resources
  resources: () => `${resourcesSlug}`,
  gallery: () => `${resourcesSlug}/gallery`,
  faqs: () => `${resourcesSlug}/faqs`,
  blog: () => `${resourcesSlug}/blog`,

  // contact-us
  contactUs: () => `${baseUrl}/contact-us`,
};
