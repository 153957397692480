export enum ESTATES_ENUM {
  PHASE_ONE = "phase-one",
  PHASE_TWO = "phase-two",
  PHASE_THREE = "phase-three",
  TIARA = "tiara-one",
  TIARA_TWO = "tiara-two",
  CITY_FTZ = "amen-city-ftz",
}

export const PROD_BASE_URL = "https://amenestate.com";

export const DEFAULT_API_ERROR = "There was an error processing your request";
export const DEFAULT_API_SUCCESS = "Your request has been sent. We'll get in touch with you shortly";
